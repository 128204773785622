// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a52510a5-e316-4540-9747-f4c248a28093",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "aws_mobile_analytics_app_id": "7d8509dc7996463990279e14a4014e7e",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
